import React, { Component } from 'react';
import Axios from 'axios';
import Spinner from '../../spinner/Spinner';
import {
    FormGroup,
    Form,
    Input,
    Card,
    CardBody,
    Row,
    Col,
    Button,
    Container,
    CardHeader
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { ExitStatus } from 'typescript';

export default class AddCategory extends Component {
    constructor(props) {
        super(props)

        //state elements
        this.state = {
            name: '',
            loading: false,
        }
    }

    //Set name state on change 
    handlenameChange = event => {
        this.setState({
            name: event.target.value
        })
    }

    //logout
    logout = () => {
        //remove token from local storage
        window.localStorage.removeItem("token");
        //redirect to login page
        this.props.history.push("/");
    }

    componentDidMount() {
        //get token from local storage
        if (!window.localStorage.getItem("token")) {
            //redirect to Login
            this.props.history.push("/");
        }
    }

    handleSubmit = (event) => {
        //set loading to true to show spinner
        this.setState({ loading: true })
        //get token from local storage
        let token = JSON.parse(window.localStorage.getItem("token"));
        //Strip of Bearer and space from token
        let alteredToken = token.substr(7);

        //base url
        let url = "https://api.smow.ca";
        //form-data
        let data = {
            "name": this.state.name
        };

        //create axios instance
        const authAxios = Axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${alteredToken}`,

            },
        });


        //Make API Call
        authAxios.post('/admin/category', data)

            .then((response) => {

                if (response.data.success) {
                    //set loading to false
                    this.setState({ loading: false, name: "" })
                    alert("Category Added Successfully!")
                    window.location.href = "/admin/add-category"
                    return false;
                }

            }).catch(error => {
                alert("Failed!")
                this.setState({ loading: false })
            });

        event.preventDefault();
    }

    render() {
        const { name } = this.state;
        if (this.state.loading) {
            return (
                <Spinner />
            )
        } else {

            return (
                <>
                    <Header />
                    {/* Page content */}
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="mb-5 mb-xl-0" xl="8">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h2 className="mb-0">Add Category</h2>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Form role="form" onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col md="7">
                                                    <FormGroup>
                                                        <label>Category Name</label>
                                                        <Input
                                                            id="category_name"
                                                            placeholder="Enter Category Name..."
                                                            type="text"
                                                            value={name}
                                                            onChange={this.handlenameChange}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Button color="primary">ADD Category</Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            )
        }
    }
}