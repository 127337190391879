import React, { Component } from 'react';
import Axios from 'axios';
import Spinner from '../../spinner/Spinner';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Row,
    Col,
    Input,
    Container,
    CardHeader

} from "reactstrap"

import Header from "components/Headers/Header.js";

export default class EditServiceCategory extends Component {

    constructor(props) {
        super(props)



        //state elements
        this.state = {
            category: '',
            id: '',
            serviceCategoryName: '',
            loading: false,

        }
    }

    //Set name state on change 
    handlenameChange = event => {
        this.setState({
            serviceCategoryName: event.target.value
        })
    }

    async componentDidMount() {
        //set loading to true to show spinner
        this.setState({ loading: true })
        //get token from local storage
        if (!window.localStorage.getItem("token")) {
            //redirect to Login if token not found in local storage
            this.props.history.push("/");
        }

        let id = window.location.href;
        let arr = id.split('/');
        let actualId = arr[5];

        //set id to state
        this.setState({ id: actualId });


        //get token from local storage
        let token = JSON.parse(window.localStorage.getItem("token"));
        //Strip of Bearer and space from token
        let alteredToken = token.substr(7);
        console.log(alteredToken)



        //Base Url
        let url = "https://api.smow.ca";

        // //create axios instance
        const authAxios = Axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${alteredToken}`,

            },
        });

        // //Make Api call
        await authAxios.get(`/admin/servicecategory/${actualId}`)
            .then(response => {
                this.setState({
                    loading: false,
                    category: response.data.category.id,
                    serviceCategoryName: response.data.name,
                })
            }
            )
            .catch(error => {
                console.log(error)
                this.setState({ loading: false })
            });


    }



    //Handle form Submit
    handleSubmit = (e) => {
        //set loading to true to show spinner
        this.setState({ loading: true })

        let data = {
            "category": this.state.category,
            "name": this.state.serviceCategoryName
        }


        let token = JSON.parse(window.localStorage.getItem("token"));
        let alteredToken = token.substr(7);

        let url = "https://api.smow.ca";

        const authAxios = Axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${alteredToken}`,

            },
        });

        authAxios.patch(`/admin/servicecategory/${this.state.id}`, data)
            .then((response) => {
                window.location.href = url + "/admin/list-service-category";
                this.setState({ loading: false })
            })
            .catch(error => {
                alert(error)
                this.setState({ loading: false })
            });

        e.preventDefault();
    }


    render() {
        if (this.state.loading) {
            return (
                <Spinner />
            )
        } else {
            return (

                <>
                    <Header />
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col>
                                <Card className="shadow">

                                    <CardHeader className="bg-transparent">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h2 className="mb-0">Edit Service Category</h2>
                                            </div>
                                        </Row>
                                    </CardHeader>

                                    <CardBody>
                                        <Form role="form" onSubmit={this.handleSubmit}>
                                            <CardTitle>Edit Service Category</CardTitle>
                                            <Row>
                                                <Col md="7">
                                                    <FormGroup>
                                                        <label>Service Category Name</label>
                                                        <Input
                                                            id="category_name"
                                                            placeholder="Enter Service Category Name..."
                                                            type="text"
                                                            value={this?.state?.serviceCategoryName}
                                                            onChange={this.handlenameChange}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Button color="primary">Edit Service Category</Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>

            )
        }
    }


}