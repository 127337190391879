import React, { Component } from "react";
import Axios from 'axios';
import LoginSpinner from '../../spinner/LoginSpinner';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

export default class Login extends Component {
  constructor(props) {
    super(props)

    //state elements
    this.state = {
      email: '',
      password: '',
      loading: '',
    }
  }

  componentDidMount() {
    //If token is set in local storage then redirect back to dashboard 
    if (window.localStorage.getItem("token")) {
      //redirect to Login
      this.props.history.push("/admin/index");

    }
  }

  //Set email state on change 
  handleEmailChange = event => {
    this.setState({
      email: event.target.value
    })
  }

  //Set password state on change 
  handlePasswordChange = event => {
    this.setState({
      password: event.target.value
    })
  }

  //Submit form to api
  handleSubmit = (e) => {
    this.setState({ loading: true })
    let url = "https://api.smow.ca/auth/admin/login";
    let data = this.state;

    //set application headers
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      'Content-Length': '286',
      'Connection': 'keep-alive',
      'Keep-Alive': 'timeout=5'
    };


    //Make API Call
    Axios.post(url, JSON.stringify(data), { headers })

      .then((response) => {

        if (response.data.success) {
          //store token in localhost
          localStorage.setItem('token', JSON.stringify(response.data.token))
          this.setState({ loading: false })
          //redirect to dashboard
          this.props.history.push("/admin/index");
        }

      }).catch(error => {
        alert("Invalid Login Credentials!")
        this.setState({ loading: false })
      });




    e.preventDefault();
  }


  render() {
    const { email, password } = this.state;
    if (this.state.loading) {
      return (
        <LoginSpinner />
      )
    } else {
      return (
        <>
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-5">
                <CardTitle className="text-center font-weight-bold">Admin Panel</CardTitle>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">

                <Form role="form" onSubmit={this.handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        value={email}
                        onChange={this.handleEmailChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={this.handlePasswordChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                </a>
              </Col>
            </Row>
          </Col>
        </>
      );
    }
  }
};

