import React, { Component } from 'react';
import Axios from 'axios';
import Spinner from '../../spinner/Spinner';
import {
    Button,
    Table,
    Input,
    Modal,
    Row,
    Col,
    Form,
    FormGroup,
    Card,
    CardHeader,
    Container
} from "reactstrap"

import Header from "components/Headers/Header.js";

export default class ListCategory extends Component {
    constructor(props) {
        super(props)

        this.redirectHandler = this.redirectHandler.bind(this);
        this.redirectServiceHandler = this.redirectServiceHandler.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);

        //state elements
        this.state = {
            category: [],
            exampleModal: false,
            loading: false,
        }
    }

    //toggle modal
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    async componentDidMount() {
        //set loading to true
        this.setState({ loading: true });
        //get token from local storage
        if (!window.localStorage.getItem("token")) {
            //redirect to Login if token not found in local storage
            this.props.history.push("/");
        }

        //get token from local storage
        let token = JSON.parse(window.localStorage.getItem("token"));
        //Strip of Bearer and space from token
        let alteredToken = token.substr(7);

        //Base Url
        let url = "https://api.smow.ca";

        //create axios instance
        const authAxios = Axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${alteredToken}`,

            },
        });

        //Make Api call
        await authAxios.get('/common/list/categories')
            .then((response) => {
                this.setState({ category: response.data, loading: false })
            })
            .catch();


    }

    //Redirect to edit category page
    redirectHandler(id) {
        window.location.href = `/admin/edit-category/${id}`
    }

    //redirect to add service category page
    redirectServiceHandler(id) {
        window.location.href = `/admin/add-service-category/${id}`
    }

    //delete a particular
    deleteHandler(id) {
        //set loading to true
        this.setState({ loading: true });

        //get token from local storage
        let token = JSON.parse(window.localStorage.getItem("token"));
        //Strip of Bearer and space from token
        let alteredToken = token.substr(7);

        //Base Url
        let url = "https://api.smow.ca";

        //create axios instance
        const authAxios = Axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${alteredToken}`,

            },
        });


        //Make Api call
        authAxios.delete(`admin/category/${id}`)
            .then((response) => {
                alert("Category Deleted Successfully!")
                this.setState({ loading: false })
                window.location.reload(true)
            })
            .catch(error => {
                alert(error)
                this.setState({ loading: false })
            });

    }


    render() {
        if (this.state.loading) {
            return (
                <Spinner />
            )
        } else {

            return (

                <>
                    <Header />
                    {/* Page content */}
                    <Container className="mt--7" fluid>
                        {/* Table */}
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <h3 className="mb-0">Category</h3>
                                    </CardHeader>
                                    <Table className="align-items-center" responsive >
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Category Name</th>
                                                <th scope="col">Action</th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.category.map(category =>

                                                    <tr key={category?._id} id="tr">
                                                        <td>{category?.name}</td>
                                                        <td>
                                                            <Button color="default" onClick={() => this.redirectHandler(category?._id)}>
                                                                <span className="btn-inner--icon">
                                                                    <i className="fas fa-edit fa-lggit " />
                                                                </span>
                                                                <span className="btn-inner--text">Edit</span>
                                                            </Button>
                                                        </td>
                                                        <td>
                                                            <Button size="lg" color="danger" onClick={() => this.deleteHandler(category?._id)}>
                                                                <span className="btn-inner--icon">
                                                                    <i className="fas fa-trash " />
                                                                </span>
                                                                <span className="btn-inner--text">Delete </span>
                                                            </Button>
                                                        </td>

                                                        <td><Button color="info" onClick={() => this.redirectServiceHandler(category?._id)}>Add Service Category</Button></td>


                                                    </tr>

                                                )

                                            }
                                        </tbody>
                                    </Table>

                                    {/* modal */}
                                    <Modal
                                        className="modal-dialog-centered"
                                        isOpen={this.state.exampleModal}
                                        toggle={() => this.toggleModal("exampleModal")}
                                    >
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">
                                                Modal title
                                            </h5>
                                            <button
                                                aria-label="Close"
                                                className="close"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={() => this.toggleModal("exampleModal")}
                                            >
                                                <span aria-hidden={true}>×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <Form role="form" onSubmit={this.handleSubmit}>
                                                <div className="display-4">Add Category</div>
                                                <Row>
                                                    <Col md="7">
                                                        <FormGroup>
                                                            <label>Category Name</label>
                                                            <Input
                                                                id="category_name"
                                                                placeholder="Enter Category Name..."
                                                                type="text"
                                                                onChange={this.handlenameChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <Button color="primary">ADD Category</Button>
                                            </Form>
                                        </div>
                                        <div className="modal-footer">
                                            <Button
                                                color="secondary"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={() => this.toggleModal("exampleModal")}
                                            >
                                                Close
                                            </Button>
                                            <Button color="primary" type="button">
                                                Save changes
                                            </Button>
                                        </div>
                                    </Modal>
                                    {/* modal */}


                                </Card>
                            </div>
                        </Row>
                    </Container>
                </>

            )

        }
    }
}